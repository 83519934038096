<template>
  <div class="resize-observer" ref="el">
    <slot />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'resize-observer',
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.OnResize = debounce(async (e, r) => {
      await this.$nextTick();
      this.$emit('on-resize', { e, r });
      this.$emit('on-resize-rect', e?.[0].contentRect);
    }, this.debounce);
    this.observer = new ResizeObserver(this.OnResize);
    this.observer.observe(this.$refs.el);
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  props: {
    debounce: {
      type: Number,
      default: 100,
    },
  },
};
</script>
